import styled from "styled-components";
import { IMG_CDN_URL } from "constants/pathConstant";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import { FONTS } from "baseStyles";

export const Wrapper = styled.div`
  background-image: url(${IMG_CDN_URL}/file/2d8c4306404.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 109rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  padding-top: 30rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    background: #292929;
    gap: 20rem;
    height: 100vh;
    justify-content: center;
    padding: 20rem 0;
  }
`;

export const ErrorText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  & > :nth-child(1) {
    font-size: 15rem;
    font-family: ${FONTS.B};
    font-weight: 700;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      font-size: 3.2rem;
    }
  }

  & > :nth-child(2) {
    font-size: 7rem;
    display: flex;
    gap: 2rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      font-size: 2.8rem;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const BackBtn = styled.div`
  font-size: 2rem;
  background: #6aa4a0;
  padding: 2rem 4rem;
  border-radius: 3rem;
  font-weight: 700;
  cursor: pointer;

  > div {
    display: flex;
    gap: 1rem;

    & > :nth-child(1) {
      font-family: ${FONTS.EB};
    }
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    padding: 2rem 2rem;
    font-size: 1.6rem;
  }
`;
