import { Address, Contact, Mail, Support, Wrapper } from "./style";
import subjectJson from "./contact-subject.json";
import { ChangeEvent, useEffect, useState } from "react";
import { IMG_CDN_URL } from "constants/pathConstant";
import { EMAIL_REG } from "constants/regConstants";
import { axiosInstance } from "api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLanguage } from "redux/reducers/languageReducer";
import { useMutation } from "react-query";
import LoadingModal from "components/Loading/LoadingModal";

const ContactUs = () => {
  const { t } = useTranslation("languageNewVer");
  const language = useSelector(selectLanguage);

  const [subject, setSubject] = useState<string>(subjectJson.subjects[0].name);
  const [name, setName] = useState<string>("");
  const [from, setFrom] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [totalPass, setTotalPass] = useState<boolean>(false);

  useEffect(() => {
    if (validateEmail(from) && validateMessage(message)) {
      setTotalPass(true);
    } else {
      setTotalPass(false);
    }
  }, [name, from, message]);

  const validateEmail = (string: string) => {
    const regExp = new RegExp(EMAIL_REG);

    return regExp.test(string);
  };

  const validateMessage = (string: string) => {
    return string.length !== 0;
  };

  const { mutate, isLoading } = useMutation(
    async (data: any) => {
      return await axiosInstance.post("/mail/get", data);
    },
    {
      onSuccess: () => {
        setName("");
        setFrom("");
        setMessage("");
        alert("메일이 성공적으로 전송되었습니다.");
      },
      onError: () => {
        window.alert("메일 전송에 실패하였습니다.");
      },
    },
  );

  const sendMessage = () => {
    if (totalPass) {
      const data = { name, from, message, subject };
      mutate(data); // mutate 함수로 요청 실행
    }
  };

  return (
    <Wrapper>
      <Support.Wrapper>
        <Support.Container>
          <Support.Title>
            <p>{t("S_500410")}</p>
            <p>{t("S_500411")}</p>
          </Support.Title>

          <Mail.Wrapper>
            <Mail.Logo src={`${IMG_CDN_URL}/file/f5c3cb27mail_FILL0_wght400_GRAD0_opsz24 1.webp`} alt="mail logo" />
            <Mail.Content>support@sketchproject.io</Mail.Content>
          </Mail.Wrapper>

          <Address.Wrapper>
            <Address.Logo
              src={`${IMG_CDN_URL}/file/5287acadlocation_on_FILL0_wght400_GRAD0_opsz24 1.webp`}
              alt="address logo"
            />
            <Address.Content>
              <p>SKT Research PTE. LTD. </p>
              <p>20 CECIL STREET #05-03 PLUS SINGAPORE</p>
            </Address.Content>
          </Address.Wrapper>
        </Support.Container>

        <Support.Container>
          <Contact.Wrapper>
            <Contact.Title>{t("S_500420")}</Contact.Title>

            <Contact.SubjectWrapper>
              {subjectJson.subjects.map((subjectJson) => (
                <Contact.SubjectBtn
                  selected={subject === subjectJson.name}
                  onClick={() => setSubject(subjectJson.name)}
                  key={subjectJson.id}
                >
                  {language === "en" ? subjectJson.name : subjectJson.nameKr}
                </Contact.SubjectBtn>
              ))}
            </Contact.SubjectWrapper>

            <Contact.TextWrapper>
              <Contact.Input
                placeholder={t("S_500550")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                length={name.length}
                value={name}
              />
              <Contact.Input
                placeholder={t("S_500560")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFrom(e.target.value)}
                length={from.length}
                value={from}
              />
              <Contact.TextArea
                placeholder={t("S_500570")}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
                length={message.length}
                value={message}
              />
            </Contact.TextWrapper>

            <Contact.BtnWrapper>
              <Contact.SendBtn pass={totalPass}>
                <Contact.SendLogo src={`${IMG_CDN_URL}/file/46255a0aVector.webp`} alt="send logo" />
                <Contact.SendText onClick={sendMessage}>{t("S_500500")}</Contact.SendText>
              </Contact.SendBtn>
            </Contact.BtnWrapper>
          </Contact.Wrapper>
        </Support.Container>
      </Support.Wrapper>

      {isLoading && <LoadingModal />}
    </Wrapper>
  );
};

export default ContactUs;
