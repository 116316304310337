import { IMG_CDN_URL } from "constants/pathConstant";
import { Content, Title, Wrapper } from "./style";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Com2 = () => {
  const { t } = useTranslation("languageNewVer");
  const isPc = useMediaQuery({
    query: "(min-width:900px)",
  });

  return (
    <Wrapper>
      <Title>
        <p>{t("S_500170")}</p>
        <p>{t("S_500171")}</p>
      </Title>

      <Content.Title>{t("S_500180")}</Content.Title>

      <Content.Wrapper>
        <Content.Container>
          <Content.Box>
            <Content.BoxImg src={`${IMG_CDN_URL}/file/7e20bbe3security_icon.png`} alt="security_icon" />
            <Content.BoxTitle>{t("S_500190")}</Content.BoxTitle>
            <Content.BoxContent>{t("S_500200")}</Content.BoxContent>
          </Content.Box>
        </Content.Container>

        {isPc && (
          <Content.PlusBox>
            <img src={`${IMG_CDN_URL}/file/b94c8adbplus_icon.png`} alt="plus_icon" />
          </Content.PlusBox>
        )}

        <Content.Container>
          <Content.Box>
            <Content.BoxImg src={`${IMG_CDN_URL}/file/ad494dd5benefit_icon.png`} alt="benefit_icon" />
            <Content.BoxTitle>{t("S_500210")}</Content.BoxTitle>
            <Content.BoxContent>{t("S_500220")}</Content.BoxContent>
          </Content.Box>
        </Content.Container>

        {isPc && (
          <Content.PlusBox>
            <img src={`${IMG_CDN_URL}/file/b94c8adbplus_icon.png`} alt="plus_icon" />
          </Content.PlusBox>
        )}

        <Content.Container>
          <Content.Box>
            <Content.BoxImg src={`${IMG_CDN_URL}/file/299551acshop_icon.png`} alt="shop_icon" />
            <Content.BoxTitle>{t("S_500240")}</Content.BoxTitle>
            <Content.BoxContent>{t("S_500250")}</Content.BoxContent>
          </Content.Box>
        </Content.Container>
      </Content.Wrapper>
    </Wrapper>
  );
};

export default Com2;
