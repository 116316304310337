import { AOS_PAY_URL, AOS_WALLET_URL, IMG_CDN_URL, IOS_PAY_URL, IOS_WALLET_URL } from "constants/pathConstant";
import {
  Background,
  LearnMoreBtn,
  QrContainer,
  Slogan,
  SubTitle,
  UrlContainer,
  UrlContainerPhone,
  Wrapper,
} from "./style";
import { useSelector } from "react-redux";
import { selectLanguage } from "redux/reducers/languageReducer";
import { useTranslation } from "react-i18next";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useMediaQuery } from "react-responsive";

const Com1 = () => {
  const { t } = useTranslation("languageNewVer");
  const language = useSelector(selectLanguage);
  const nav = useNavigatePage();
  const isPc = useMediaQuery({
    query: "(min-width:900px)",
  });

  // img
  const GoogleImgEn = `${IMG_CDN_URL}/file/f0dd9be8googlestore_en.webp`;
  const GoogleImgKr = `${IMG_CDN_URL}/file/9326f2ffgooglestore_kr.webp`;
  const AppImgEn = `${IMG_CDN_URL}/file/b3c63dd2apple_icon_en-min.webp`;
  const AppImgKr = `${IMG_CDN_URL}/file/287b909fapple_icon-min.webp`;

  return (
    <Wrapper>
      <Background>
        <Slogan>
          <p>
            <span>{t("S_500020")}</span>
            <span>{t("S_500021")}</span>
          </p>
          <p>{t("S_500022")}</p>
          <p>{t("S_500023")}</p>
        </Slogan>

        <SubTitle>
          <p>
            <span>{t("S_500030")}</span>
            <span>{t("S_500031")}</span>
          </p>
          {/* <p>
            <span>{t("S_500032")}</span>
            <span>{t("S_500033")}</span>
          </p> */}
        </SubTitle>

        <LearnMoreBtn onClick={() => nav("/apps")}>{t("S_500040")}</LearnMoreBtn>

        <QrContainer>
          {/* Sketch Wallet 링크 */}
          <div>
            <p>{t("S_600070")}</p>
            <UrlContainer>
              <div>
                <img loading="lazy" src={`${IMG_CDN_URL}/file/bbba5b63sketchWallet.png`} alt="sketchwallet_qr" />
              </div>
              <div>
                <div>
                  <a href={AOS_WALLET_URL} target="_blank" rel="noopener noreferrer">
                    <img src={language === "en" ? GoogleImgEn : GoogleImgKr} alt="googlePlayStore" loading="lazy" />
                  </a>
                </div>
                <div>
                  <a href={IOS_WALLET_URL} target="_blank" rel="noopener noreferrer">
                    <img src={language === "en" ? AppImgEn : AppImgKr} alt="AppleStore" loading="lazy" />
                  </a>
                </div>
              </div>
            </UrlContainer>
          </div>
        </QrContainer>

        <UrlContainerPhone>
          <div>
            <img src={`${IMG_CDN_URL}/file/6332b1f4logo.svg`} alt="logo_img" />
          </div>
          {isPc ? (
            <p>
              <span>{t("S_500051")}</span>
              <span>{t("S_500052")}</span>
            </p>
          ) : (
            <p>{t("S_500050")}</p>
          )}

          <div>
            <div>
              <div>{t("S_600070")}</div>
              <div>
                <div>
                  <a href={AOS_WALLET_URL} target="_blank" rel="noopener noreferrer">
                    <img src={language === "en" ? GoogleImgEn : GoogleImgKr} alt="googlePlayStore" loading="lazy" />
                  </a>
                </div>
                <div>
                  <a href={IOS_WALLET_URL} target="_blank" rel="noopener noreferrer">
                    <img src={language === "en" ? AppImgEn : AppImgKr} alt="AppleStore" loading="lazy" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </UrlContainerPhone>
      </Background>
    </Wrapper>
  );
};

export default Com1;
