import { FONTS } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
    margin-top: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;

export const Category = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: center;
`;

export const CategoryText = styled.p<IStyle>`
  font-size: 2.4rem;
  font-family: ${FONTS.EB};
  cursor: pointer;
  border-bottom: ${(props) => props.borderBt && "1px solid #fff"};
  padding: 1.5rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    font-size: 1.8rem;
  }
`;

interface IStyle {
  borderBt: boolean;
}
