import { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { offNoticeModal } from "redux/reducers/noticeModalReducer";
import { Container, ImgBox, LangBtn, TextBox, Wrap, Wrapper } from "./style";
import { useSelector } from "react-redux";
import { changeLanguage, selectLanguage } from "redux/reducers/languageReducer";
import { IS_IOS } from "constants/pathConstant";
import { useTranslation } from "react-i18next";

const NoticeModal = ({ data }) => {
  const setCookie = useCookies()[1];
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const { t } = useTranslation("languageNewVer");

  const [isActive, setIsActive] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const checkHandler = () => {
    setIsChecked(!isChecked);
  };

  const closeHandler = () => {
    if (isChecked) {
      const now = new Date();
      const expires = new Date(now.setDate(now.getDate() + 1));
      setCookie(`sketch_htm${data.index}`, true, { path: "/", expires });
    }

    setIsActive(false);
    dispatch(offNoticeModal());
  };

  const imgClickHandler = () => {
    if (data.linkTarget || data.iosLinkTarget) window.open(IS_IOS ? data.iosLinkTarget : data.linkTarget);
  };

  return (
    <>
      {isActive && (
        <Wrap>
          <Wrapper>
            <div onClick={imgClickHandler}>
              <img loading="lazy" src={language === "en" ? data.linkImgEng : data.linkImg} alt="NoticeImg" />
            </div>
            <Container>
              <TextBox onClick={checkHandler}>
                <ImgBox bg={isChecked}>
                  {isChecked && <img loading="lazy" src="/assets/img/Modal_1.svg" alt="CheckImg" />}
                </ImgBox>
                <span>{t("S_500600")}</span>
              </TextBox>
              <TextBox>
                <LangBtn onClick={() => dispatch(changeLanguage())}>{language === "en" ? "한글" : "EN"}</LangBtn>
                <span onClick={closeHandler}>{t("S_500610")}</span>
              </TextBox>
            </Container>
          </Wrapper>
        </Wrap>
      )}
    </>
  );
};

export default NoticeModal;
