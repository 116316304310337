import React, { useState } from "react";
import { ContentsA, ContentsList, ContentsQ } from "./style";
import { IFaqDataType } from "./type";
import { useSelector } from "react-redux";
import { selectLanguage } from "redux/reducers/languageReducer";
import { IMG_CDN_URL } from "constants/pathConstant";

const ContentsDetail = ({ v, highlightKeyword }: { v: IFaqDataType; highlightKeyword: string }) => {
  const language = useSelector(selectLanguage);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const highlightKeywords = (text: string) => {
    return text.split(new RegExp(`(${highlightKeyword})`, "gi")).map((keyword, index) => (
      <span key={index} style={{ color: keyword.toLowerCase() === highlightKeyword.toLowerCase() ? "#B1E5E2" : "" }}>
        {keyword}
      </span>
    ));
  };

  return (
    <ContentsList>
      <ContentsQ
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <div>
          <p>{language === "en" ? highlightKeywords(v.questionEng) : highlightKeywords(v.questionKr)}</p>
        </div>
        <div>
          <img
            src={
              isOpen ? `${IMG_CDN_URL}/file/257438e3minus_icon.png` : `${IMG_CDN_URL}/file/642f78d2faq_plus_icon.svg`
            }
            alt="click_icon"
          />
        </div>
      </ContentsQ>
      {isOpen && (
        <ContentsA dangerouslySetInnerHTML={{ __html: language === "en" ? v.answerEng : v.answerKr }}></ContentsA>
      )}
    </ContentsList>
  );
};

export default ContentsDetail;
