import { useTranslation } from "react-i18next";
import { Category, CategoryText, Wrapper } from "./style";
import { useState } from "react";
import Faq from "./Faq";
import ContactUs from "./ContactUs";

const Support = () => {
  const { t } = useTranslation("languageNewVer");
  const [category, setCategory] = useState<string>("1");

  const categories = [
    { id: "1", label: t("S_500510"), component: <Faq /> },
    { id: "2", label: t("S_500520"), component: <ContactUs /> },
  ];

  // const categories = [
  //   // { id: "1", label: t("S_500510"), component: <Faq /> },
  //   { id: "1", label: t("S_500520"), component: <ContactUs /> },
  // ];

  return (
    <Wrapper>
      <Category>
        {categories.map((cat) => (
          <CategoryText borderBt={cat.id === category} key={cat.id} onClick={() => setCategory(cat.id)}>
            {cat.label}
          </CategoryText>
        ))}
      </Category>
      {categories.find((cat) => cat.id === category)?.component}
    </Wrapper>
  );
};

export default Support;
