import React, { useEffect } from "react";
import { Wrapper } from "./DynamicLinkPage.style";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import axios from "axios";
import Loading from "components/Loading";

export const DynamicLinkPage = () => {
  const params = useParams();

  window.location.href = process.env.REACT_APP_DYNAMIC_LINK! + params.shortLink;

  return (
    <Wrapper>
      <img src="/assets/img/Header_3.svg" alt="logo" />
      <Loading />
    </Wrapper>
  );
};
