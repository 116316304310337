import React from "react";
import "./style.css";

const Loading = () => {
  return (
    <div className="wrap go">
      <div className="loader orbit">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div>Loading</div>
    </div>
  );
};

export default Loading;
