import React from "react";
import "./style.css";

const LoadingModal = () => {
  return (
    <div className="modal-wrap">
      <div className="wrap go">
        <div className="loader orbit">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>Loading</div>
      </div>
    </div>
  );
};

export default LoadingModal;
