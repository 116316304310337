import { Dispatch, SetStateAction, useState } from "react";
import { PageListBox, PageListIcon, PageListNumber } from "./sytle";
import PageLeftSvg from "components/Svg/pageLeft";
import PageRightSvg from "components/Svg/pageRight";

const SupportPagination = ({
  count,
  currentPage,
  setCurrentPage,
  admin = false,
}: {
  count: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  admin?: boolean;
}) => {
  const [startPage, setStartPage] = useState(1);
  //   const [isActive, setIsActive] = useState(0);

  // 10의자리 인덱스
  //   const [pageIndex, setPageIndex] = useState(Math.floor(page / pagingSize));
  const [pageLength, setPageLength] = useState(0);

  const lastPage = count != null ? Math.ceil(count / 10) : 0;

  const onClickPage = (e) => {
    setCurrentPage(Number(e.currentTarget.value));
  };

  // 페이지 이동 ( < | > 누르는 경우)
  // TODO: 더미데이터 넣어보고 확인
  // const pageIndexHandler = (dir) => {
  //   if (dir === "up") {
  //     setStartPage(startPage - 10);
  //     setCurrentPage(0);
  //   } else {
  //     if (startPage + 10 > lastPage) return;
  //     setStartPage(startPage + 10);
  //     setCurrentPage(0);
  //   }
  // };

  // 끝 페이지 이동 ( << | >> 누르는 경우)
  // TODO: 더미데이터 넣어보고 확인
  //   const endPageHandler = (dir) => {
  //     if (dir === "up") {
  //       if (pageIndex + 1 > pageLength / 10) return;
  //       nav(url + (pageLength - 1));
  //       setPageIndex(Math.floor(pageLength / 10));
  //     } else {
  //       if (pageIndex - 1 < 0) return;
  //       nav(url + 0);
  //       setPageIndex(0);
  //     }
  //   };

  return (
    <PageListBox admin={admin}>
      <PageListIcon pageLength={pageLength}>
        {/* <div onClick={() => endPageHandler("down")}>
          <PageDLeftSvg />
        </div> */}
        {/* <div onClick={() => pageIndexHandler("down")}>
          <PageLeftSvg />
        </div> */}
      </PageListIcon>
      {new Array(10).fill(1).map(
        (_, index) =>
          index + startPage <= lastPage && (
            <PageListNumber
              key={index + startPage}
              id={String(index + startPage)}
              onClick={onClickPage}
              value={index}
              isSelected={currentPage === index}
              admin={admin}
            >
              {index + startPage}
            </PageListNumber>
          ),
      )}
      <PageListIcon pageLength={pageLength}>
        {/* <div onClick={() => pageIndexHandler("up")}>
          <PageRightSvg />
        </div> */}
        {/* <div onClick={() => endPageHandler("up")}>
          <PageDRightSvg />
        </div> */}
      </PageListIcon>
    </PageListBox>
  );
};

export default SupportPagination;
