import { useNavigatePage } from "hooks/useNavigatePage";
import { Wrapper, ErrorText, BackBtn } from "./style";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const nav = useNavigatePage();
  const { t } = useTranslation("languageNewVer");

  return (
    <Wrapper>
      <ErrorText>
        <p>{t("S_500700")}</p>
        <div>
          <p>{t("S_500710")}</p>
          <p>{t("S_500711")}</p>
        </div>
      </ErrorText>
      <BackBtn onClick={() => nav("/")}>
        <div>
          <p>{`<`}</p>
          <p>{t("S_500720")}</p>
        </div>
      </BackBtn>
    </Wrapper>
  );
};

export default NotFound;
