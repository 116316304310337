import { FONTS } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import { IMG_CDN_URL } from "constants/pathConstant";
import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  margin-top: 10rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin-top: 6rem;
  }
`;

export const Title = styled.div`
  font-size: 6.4rem;
  text-align: center;

  & > p {
    font-family: ${FONTS.H};
    line-height: 1.5;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    font-size: 2.4rem;
  }
`;

export const Background = styled.div<IStyle>`
  width: 100%;
  height: 92rem;
  margin-top: 4rem;
  background: url(${IMG_CDN_URL}/file/6c85fef9bg3-min.webp) no-repeat;
  background-size: contain;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    background: url(${IMG_CDN_URL}/file/6e5bdfbdbg3_phone.webp) no-repeat;
    background-size: contain;
    margin-top: ${(props) => (props.lang ? "12.2rem" : "12.2rem")};
    height: 32rem;
    
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 7.8rem 3.6rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: none;
    }
  }
`;

export const TextBox = styled.div<IStyle>`
  width: 52rem;
  padding: 6.7rem;
  background: rgba(0, 0, 0, 0.5);
  border: 2.4rem;
  border-radius: 2.4rem;
  font-size: 2.4rem;
  line-height: ${(props) => (props.lang ? 1.05 : 1.25)};
`;



export const TextBoxPhone = styled.div`
  display: none;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: absolute; */
    margin: 3rem 1.5rem -10rem;
    gap: 2rem;

    & > :nth-child(1) {
      letter-spacing: 0.1rem;
      padding: 2rem 3rem;
      background: rgba(255, 255, 255, 0.5);
      border: 2.4rem;
      border-radius: 2.4rem;
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      line-height: 2rem;
    }

    & > :nth-child(2) {
      font-size: 1.4rem;
      font-family: ${FONTS.B};
      background: #b1e5e2;
      color: #000;
      padding: 1rem 1.8rem;
      border-radius: 10rem;
      cursor: pointer;
    }
  }
`;

export const DownloadBtn = styled.div`
  margin-top: 3rem;
  padding: 1.5rem 4rem;
  background: #b1e5e2;
  border-radius: 10rem;
  color: #000;
  font-size: 2.4rem;
  text-align: center;
  cursor: pointer;
  font-family: ${FONTS.B};
`;

interface IStyle {
  lang: boolean;
}
