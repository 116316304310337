import { useQuery } from "react-query";
import Com1 from "./components/Com1";
import Com2 from "./components/Com2";
import Com4 from "./components/Com4";
import { Wrapper } from "./style";
import { axiosInstance } from "api";
import NoticeModal from "components/Modal/NoticeModal";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";

const Home = () => {
  const [cookies] = useCookies();
  const [noticeReverse, setNoticeReverse] = useState([]);

  const CheckCookieById = (id: string) => {
    return cookies["sketch_htm" + id];
  };

  const { data: notice, isSuccess } = useQuery<any>("notices", () => axiosInstance.get("/notices"));

  useEffect(() => {
    setNoticeReverse(notice?.reverse());
  }, [notice]);

  return (
    <Wrapper>
      {isSuccess && noticeReverse?.map((v: any) => !CheckCookieById(v.index) && <NoticeModal data={v} key={v.index} />)}
      <Com1 />
      <Com2 />
      <Com4 />
    </Wrapper>
  );
};

export default Home;
