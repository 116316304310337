import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding-top: 5rem;

  & > img {
    width: 30rem;
    height: 30rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 10rem;
      height: 10rem;
    }
  }

  & > div {
    font-size: 20rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    }
  }
`;
