import { BLACK_0, BLACK_a, MAIN_GREEN } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const PageListBox = styled.div`
  /* margin: ${(props) => (props.admin ? "0" : "8rem 0 35rem")}; */
  background: ${(props) => (props.admin ? "white" : "none")};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    /* margin-top: 6rem; */
    margin-bottom: 10rem;
    gap: 1.5rem;
  }
`;

export const PageListIcon = styled.div`
  display: ${(props) => (props.pageLength <= 10 ? "none" : "flex")};
  & > div:nth-child(2) {
    margin-left: 1rem;
  }

  & > div > svg {
    cursor: pointer;
    &:hover {
      > g > g > path {
        stroke: ${MAIN_GREEN};
      }

      > g > path {
        stroke: ${MAIN_GREEN};
      }
    }
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    & > img:nth-child(1) {
      margin-right: 1rem;
    }

    & > img:nth-child(2) {
      margin-left: 1rem;
    }
  }
`;

export const PageListNumber = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.6rem;
  font-weight: ${(props) => (props.isSelected ? 700 : 300)};
  color: ${(props) => (props.isSelected ? (props.admin ? BLACK_0 : "#B1E5E2") : BLACK_a)};

  cursor: ${(props) => (props.isSelected ? "defalut" : "pointer")};

  &:hover {
    color: ${(props) => (props.admin ? "none" : "#B1E5E2")};
  }
`;
