import React, { useEffect, useState } from "react";
import { CategoryBox, ContentsBox, Input, ListBox, SearchBox, SearchBtn, Wrapper, Category } from "./style";
import { useQuery } from "react-query";
import { axiosInstance } from "api";
import { ICategoryType, IFaqDataType } from "./type";
import ContentsDetail from "./ContentsDetail";
import { useTranslation } from "react-i18next";
import SupportPagination from "components/Pagination/SupportPagination";
import Loading from "components/Loading";
import { useSelector } from "react-redux";
import { selectLanguage } from "redux/reducers/languageReducer";

const Faq = () => {
  const { t } = useTranslation("languageNewVer");
  const language = useSelector(selectLanguage);

  const [faqData, setFaqData] = useState<IFaqDataType[][]>([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지 번호

  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [highlightKeyword, setHighlightKeyword] = useState<string>("");

  const { data, isSuccess, isLoading } = useQuery<any>("manage_support", () => axiosInstance.get("/faq"));

  useEffect(() => {
    if (!data) return;

    if (isSearch) {
      const searchResult = data.list.filter(
        (v: IFaqDataType) =>
          (selectedCat ? v.categoryCd === selectedCat : data.list) &&
          (v.questionKr.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            v.questionEng.toLowerCase().includes(searchKeyword.toLowerCase())),
      );

      setFaqData(pageResult(searchResult));
    } else {
      const groupedData = data.list.filter((v: IFaqDataType) =>
        selectedCat ? v.categoryCd === selectedCat : data.list,
      );

      setFaqData(pageResult(groupedData));
    }
  }, [data, selectedCat, isSearch, highlightKeyword]);

  const pageResult = (data: IFaqDataType[], size = 10): IFaqDataType[][] => {
    const result: IFaqDataType[][] = [];
    for (let i = 0; i < data.length; i += size) {
      result.push(data.slice(i, i + size));
    }

    return result;
  };

  const handleCategory = (cat: string) => {
    setCurrentPage(0);
    setSelectedCat(cat);
  };

  const handleSearch = () => {
    setCurrentPage(0);
    setSelectedCat("");
    setIsSearch(!!searchKeyword);
    setHighlightKeyword(searchKeyword);
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  if (isLoading) return <Loading />;

  return (
    <Wrapper>
      <SearchBox>
        <Input
          placeholder={t("S_500530")}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onKeyUp={(e) => handleEnter(e.key)}
        />
        <SearchBtn onClick={handleSearch}>{t("S_500540")}</SearchBtn>
      </SearchBox>

      <ListBox>
        <CategoryBox>
          <Category
            isClicked={!selectedCat}
            onClick={() => {
              handleCategory("");
            }}
          >
            {language === "en" ? "All" : "전체"}
          </Category>
          {data.categoryList.map((v: ICategoryType) => (
            <Category
              key={v.code}
              isClicked={selectedCat === v.code}
              onClick={() => {
                handleCategory(v.code);
              }}
            >
              {language === "en" ? v.nameEng : v.nameKr}
            </Category>
          ))}
        </CategoryBox>
        <ContentsBox>
          {faqData[currentPage]?.map((v: IFaqDataType) => (
            <ContentsDetail key={v.id} v={v} highlightKeyword={highlightKeyword} />
          ))}
        </ContentsBox>
      </ListBox>

      <SupportPagination count={faqData.flat().length} setCurrentPage={setCurrentPage} currentPage={currentPage} />
    </Wrapper>
  );
};

export default Faq;
