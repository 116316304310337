import { FONTS } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import { IMG_CDN_URL } from "constants/pathConstant";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    /* padding-top: 4rem; */
    width: 100%;
    margin-top: 0;
  }

  & > p {
    color: #fff;
    font-family: ${FONTS.H};
    font-size: 6.4rem;
    margin-bottom: 3rem;
    text-align: center;
    line-height: 2.4;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: none;
    }
  }
`;

export const BackTitle = styled.div`
  @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
    background: url(${IMG_CDN_URL}/file/42716b04bg-share-earn.webp) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100rem;
    padding: 20rem 0 0 12rem;

    & > p,
    & > p > span {
      color: #000;
      font-family: ${FONTS.H};
      font-size: 9.6rem;
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    & > p,
    & > p > span {
      color: #fff;
      font-family: ${FONTS.H};
      font-size: 2.4rem;
      text-align: center;
      line-height: 1.5;
    }
  }
`;

export const Title = styled.div`
  @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin-top: 16rem;
    width: 171rem;
    height: 12.7rem;
    border-radius: 3.2rem 3.2rem 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8db3c6;
    font-family: ${FONTS.H};
    font-size: 6.4rem;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 32rem;
    height: 4.5rem;
    border-radius: 1.2rem 1.2rem 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8db3c6;
    font-family: ${FONTS.H};
    font-size: 2.4rem;
    margin-top: 4.4rem;
  }
`;

export const Content = {
  Wrapper: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 100%;
      height: 72rem;
      padding: 0 18rem 0 22rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 100%;
      padding: 4rem 3rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }
  `,

  Container: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    }
  `,
  Box: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      gap: 5rem;
      align-items: center;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      gap: 1.2rem;
    }
  `,
  BoxImg: styled.img`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 18rem;
      height: 18rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 4.8rem;
      height: 4.8rem;
    }
  `,

  BoxContainer: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  `,

  BoxTitle: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #fff;
      font-family: ${FONTS.H};
      font-size: 4.8rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #fff;

      font-family: ${FONTS.H};
      font-size: 2.4rem;
    }
  `,

  BoxContent: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #fff;
      font-family: ${FONTS.EB};
      font-size: 3.2rem;
      line-height: 4rem;

      & > span {
        color: #00ff85;
        font-family: ${FONTS.EB};
      }
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #fff;
      font-family: ${FONTS.EB};
      font-size: 1.4rem;
      line-height: 2rem;

      & > span {
        color: #00ff85;
        font-family: ${FONTS.EB};
      }
    }
  `,
};
