import { BLACK_0, BLACK_a } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 5rem 3rem 5rem 12rem;
  width: 80%;
  background: rgba(62, 62, 62, 0.5);
  display: flex;
  flex-direction: column;
  border-radius: 2.4rem;
  gap: 3rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    background: none;
    padding: 0 4rem;
    width: 100%;
    justify-content: center;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  justify-content: end;
  gap: 1.5rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
    gap: 1rem;
  }
`;

export const ListBox = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  min-height: 64rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    flex-direction: column;
    border: none;
    min-height: 0;
    gap: 3rem;
  }
`;

export const CategoryBox = styled.div`
  width: 20%;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-right: 1px solid #aaa;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    border: none;
    width: 100%;
    text-align: center;
    padding: 0;
    gap: 3rem;
  }
`;

export const Category = styled.p<IStyle>`
  padding: 2rem 1rem;
  cursor: pointer;
  color: ${(props) => props.isClicked && "#8DB3C6"};

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
    color: ${(props) => props.isClicked && "#000"};
    background: ${(props) => props.isClicked && "#B1E5E2"};
    border-radius: 0.6rem;
    border: ${(props) => (props.isClicked ? "1px solid #B1E5E2" : "1px solid rgba(120, 120, 120, 0.3)")};
  }
`;

export const ContentsBox = styled.div`
  width: 80%;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
  }
`;

export const ContentsList = styled.div`
  font-size: 1.8rem;
  padding: 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    font-size: 1.6rem;
  }
`;

export const ContentsQ = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  & > :nth-child(1) {
    width: 100rem;
    display: flex;
    align-items: center;
    line-height: 2.5rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 22rem;
      line-height: 1.9rem;
    }

    & > p {
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }

  & > :nth-child(2) {
    width: 2.4rem;
    height: 2.4rem;

    > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ContentsA = styled.p`
  color: #8db3c6;
  padding-top: 2rem;
  line-height: 2rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    font-size: 1.4rem;
  }

  a:link {
    color: #fff;
  }
  a:visited {
    color: #a5a5a5;
  }
  a:hover {
    color: #8db3c6;
  }
  a:active {
    color: #53839b;
  }
`;

export const Input = styled.input`
  width: 28.6rem;
  background-color: #fff;
  border: none;
  padding: 1.5rem 2rem;
  font-size: 1.6rem;
  color: ${BLACK_0};
  outline: none;
  border-radius: 0.6rem;

  ::placeholder {
    color: ${BLACK_a};
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
    font-size: 1.3rem;
  }
`;

export const SearchBtn = styled.div`
  background: #8db3c6;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.5rem 2rem;
  border-radius: 0.6rem;
  color: #000;
  border: 1px solid #8db3c6;
  cursor: pointer;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    font-size: 1.4rem;
    background: #b1e5e2;
  }
`;

interface IStyle {
  isClicked: boolean;
}
