import { IMG_CDN_URL } from "constants/pathConstant";
import { BackTitle, Content, Title, Wrapper } from "./style";
import { useTranslation } from "react-i18next";

const Com3 = () => {
  const { t } = useTranslation("languageNewVer");

  return (
    <Wrapper>
      <p>{t("S_500264")}</p>
      <BackTitle>
        <p>{t("S_500260")}</p>
        <p>
          <span>{t("S_500261")}</span>
          <span>{t("S_500262")}</span>
          <span>{t("S_500263")}</span>
        </p>
      </BackTitle>
      <Title>{t("S_500270")}</Title>
      <Content.Wrapper>
        <Content.Container>
          <Content.Box>
            <Content.BoxImg src={`${IMG_CDN_URL}/file/48589384arrow_s.svg`} alt="black swap img" />
            <Content.BoxContainer>
              <Content.BoxTitle>{t("S_500280")}</Content.BoxTitle>
              <Content.BoxContent>
                {t("S_500290")}
                {/* {t("S_500291")} */}
                {/* {t("S_500292")} */}
              </Content.BoxContent>
            </Content.BoxContainer>
          </Content.Box>
        </Content.Container>

        <Content.Container>
          <Content.Box>
            <Content.BoxImg src={`${IMG_CDN_URL}/file/0f8da68ewallet.svg`} alt="wallet img" />
            <Content.BoxContainer>
              <Content.BoxTitle>{t("S_500300")}</Content.BoxTitle>
              <Content.BoxContent>{t("S_500310")}</Content.BoxContent>
            </Content.BoxContainer>
          </Content.Box>
        </Content.Container>
      </Content.Wrapper>
    </Wrapper>
  );
};

export default Com3;
