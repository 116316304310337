import { FONTS, MAIN_GREEN } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 5rem;
  margin: 3rem 0;
  flex-wrap: wrap;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    gap: 4rem;
    justify-content: center;
  }
`;

export const IconBox = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  position: relative;

  a {
    color: #fff;
    text-decoration: none;
  }
`;

export const ImgBox = styled.img<IStyle>`
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.icon !== "Naver Blog" && "100%"};
  opacity: 0.4;
  cursor: pointer;
`;

export const ListWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(54, 54, 54, 1);
  z-index: 3;
  top: 5.5rem;
  left: -4.5rem;
  font-size: 2rem;
  border-radius: 0.5rem;
  padding: 1rem 0.5rem;
  width: 14rem;
  gap: 0.5rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 8rem;
    font-size: 1.5rem;
    left: -1.7rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  p {
    cursor: pointer;

    &:hover {
      /* color: ${MAIN_GREEN}; */
      font-family: ${FONTS.H};
    }
  }
`;

interface IStyle {
  icon: string;
}
