import Layout from "Layout";
import AdminLayout from "Layout/Admin/AdminLayout";
import Home from "pages/Home";
import Apps from "pages/Apps";
import Support from "pages/Support";
import AdminHome from "pages/Admin/AdminHome";
import Login from "pages/Admin/Login";
import ManageAdmin from "pages/Admin/ManageAdmin";
import ModifyNews from "pages/Admin/News/ModifyNews/ModifyNews";
import UploadNews from "pages/Admin/News/UploadNews";
import Signup from "pages/Admin/Signup";
import UploadImage from "pages/Admin/UploadImage";
import NewsEvents from "pages/NewsEvents";
import NewsHome from "pages/Admin/News/NewsHome";
import AdminNotice from "pages/Admin/AdminNotice/AdminNotice";
import NoticeUpload from "pages/Admin/AdminNotice/NoticeUpload/NoticeUpload";
import ModifyNotice from "pages/Admin/AdminNotice/ModifyNotice/ModifyNotice";
import SupportHome from "pages/Admin/Support/SupportHome";
import UploadSupport from "pages/Admin/Support/UploadSupport";
import ModifySupport from "pages/Admin/Support/ModifySupport";
import SketchWallet from "pages/Qr/SketchWallet";
import SketchPay from "pages/Qr/SketchPay";
import NotFound from "pages/NotFound";
import { Sfuel } from "pages/Admin/Sfuel/Sfuel";
import { SfuelLie } from "pages/Admin/Sfuel/tmp/SfuelLie";
import { TermBasicWallet } from "pages/Terms/TermBasicWallet";
import { TermBasicPay } from "pages/Terms/TermBasicPay";
import { TermPersonWallet } from "pages/Terms/TermPersonWallet";
import { TermPersonPay } from "pages/Terms/TermPersonPay";
import { TermMarketingWallet } from "pages/Terms/TermMarketingWallet";
import { TermMarketingPay } from "pages/Terms/TermMarketingPay";
import { DynamicLinkPage } from "../pages/DynamicLink/DynamicLinkPage";

const { useRoutes } = require("react-router");

const NewRouter = () => {
  const routes = [
    {
      path: "*",
      element: <NotFound />,
    },
    {
      path: "/url/:shortLink",
      element: <DynamicLinkPage />,
    },
    {
      path: "/sketch_wallet",
      element: <SketchWallet />,
    },
    {
      path: "/sketch_pay",
      element: <SketchPay />,
    },
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "apps",
          element: <Apps />,
        },
        {
          path: "support",
          element: <Support />,
        },
        {
          path: "news_events/:page",
          element: <NewsEvents />,
        },
      ],
    },
    {
      path: "/admin-sketch",
      element: <AdminLayout />,
      children: [
        {
          path: "",
          element: <AdminHome />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "signup",
          element: <Signup />,
        },
        {
          path: "news/manage/:page",
          element: <NewsHome />,
        },
        {
          path: "news/upload",
          element: <UploadNews />,
        },
        {
          path: "news/modify",
          element: <ModifyNews />,
        },
        {
          path: "upload",
          element: <UploadImage />,
        },
        {
          path: "admins/manage/:page",
          element: <ManageAdmin />,
        },
        {
          path: "notices/manage/:page",
          element: <AdminNotice />,
        },
        {
          path: "notices/upload",
          element: <NoticeUpload />,
        },
        {
          path: "notices/modify",
          element: <ModifyNotice />,
        },
        {
          path: "support/manage",
          element: <SupportHome />,
        },
        {
          path: "support/upload",
          element: <UploadSupport />,
        },
        {
          path: "support/modify",
          element: <ModifySupport />,
        },
        {
          path: "sfuel",
          element: <Sfuel />,
        },
        {
          path: "sfuel/secret",
          element: <SfuelLie />,
        },
      ],
    },

    {
      path: "/term/wallet",
      element: <TermBasicWallet />,
    },
    {
      path: "/payterm/wallet",
      element: <TermBasicPay />,
    },
    {
      path: "/term/personal",
      element: <TermPersonWallet />,
    },
    {
      path: "/payterm/personal",
      element: <TermPersonPay />,
    },
    {
      path: "/term/marketing",
      element: <TermMarketingWallet />,
    },
    {
      path: "/payterm/marketing",
      element: <TermMarketingPay />,
    },
  ];
  return useRoutes(routes);
};

export default NewRouter;
